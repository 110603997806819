// @flow
import * as React from 'react'
import styled from 'styled-components'
import { flex } from 'styled-system'

import Layout from 'src/components/Layout'
import Flex from 'src/components/Flex'
import Container from 'src/components/Container'
import Section from 'src/components/Section'
import Heading from 'src/components/Heading'
import Text from 'src/components/Text'

const HeroContent = styled.div`
  height: 100%;
  padding: 5vw;
  ${flex}
`;

class PageNotFound extends React.Component {
  render() {
    const headingSizes = [2, 3];
    const textSizes = [0, 1];

    return (
      <Layout location={this.props.location}>
        <Section theme='black'>
          <Container width='100%'>
            <HeroContent flex='1'>
              <Flex
                maxWidth={780}
                justifyContent='center'
                flexDirection='column'
                flex='1'
              >
                <Heading.h1 fontSize={headingSizes} letterSpacing={'-2px'}>Aloha pro—surfer. We create brands from their and connect them with their true fans.</Heading.h1>
                <Text.p fontSize={textSizes}>It's the product, the experience and the driving force behind the interactions that matter. If your product, service or dreams are digital, then design is critical to their success.</Text.p>
                <Text.p fontSize={textSizes}>Bumble, Google, King, Riot Games and Zoom—these are companies that thrive and survive because of design—these are companies that we’ve designed with and for.</Text.p>
                <Text.p fontSize={textSizes}>We charge based on the impact of our work and we work on referral only. Reach out and let's talk.</Text.p>
              </Flex>
            </HeroContent>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default PageNotFound